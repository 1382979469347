@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/spacing';

.uploadContainer {
  margin-bottom: spacing.$spacing-08;

  @include breakpoint-up(xlg) {
    width: 80%;
  }
}
